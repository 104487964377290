import { render, staticRenderFns } from "./case_ai.vue?vue&type=template&id=2ad1e77c&scoped=true"
import script from "./case_ai.vue?vue&type=script&lang=js"
export * from "./case_ai.vue?vue&type=script&lang=js"
import style0 from "./case_ai.vue?vue&type=style&index=0&id=2ad1e77c&prod&scoped=true&lang=css"
import style1 from "./case_ai.vue?vue&type=style&index=1&id=2ad1e77c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ad1e77c",
  null
  
)

export default component.exports